import GoogleLogin from 'react-google-login';
import RouterPage from './RouterPage';
import React, { useState } from 'react';


function App() {
  const [loggedIn, setLoggedIn] = useState(false);

  const handleLogin = async (response: any) => {
    const auth_url = 'https://prod-percents-internal-monorepo.onrender.com/internal/google-auth';
    const res = await fetch(auth_url, {
      method: "POST",
      body: JSON.stringify({
        token: response.tokenId
      }),
      headers: {
        "Content-Type": "application/json"
      }
    });

    if (res.status === 200) {
      const responseBody = await res.json();
      localStorage.setItem('authToken', responseBody.token);
      setLoggedIn(true);
    }
  }

  return loggedIn ? <RouterPage /> :
    <GoogleLogin
      clientId={'121316933905-0s8f3vrgmloj7pr1l5vg98qlsaqga974.apps.googleusercontent.com'}
      buttonText="Log in with Percents Google account"
      onSuccess={handleLogin}
      onFailure={handleLogin}
      cookiePolicy={'single_host_origin'}
      isSignedIn={true}
    />;
}

export default App;
