import React, { useState, useEffect } from 'react';
import MultilineChart from './MultilineChart';

const DailyVolume = () => {
    const [currMonthData, setCurrMonthData] = useState([]);
    const [prevMonthData, setPrevMonthData] = useState([]);

    useEffect(() => {

        async function fetchData() {
            const authToken = localStorage.getItem('authToken');
            const result = await fetch(
                'https://prod-percents-internal-monorepo.onrender.com/internal/monthly-volume-info',
                {
                    headers: {
                        'x-access-token': `token ${authToken}`
                    }
                }
            );

            const data = await result.json();

            const curr_month_data = data.curr_month.map((curr: { date: any; total_amount: number; }) => {
                return {
                    date: curr.date,
                    total_amount: Math.trunc(curr.total_amount / 100.0)
                }
            });

            const prev_month_data = data.prev_month.map((curr: { date: any; total_amount: number; }) => {
                return {
                    date: curr.date,
                    total_amount: Math.trunc(curr.total_amount / 100.0)
                }
            })


            setCurrMonthData(curr_month_data);
            setPrevMonthData(prev_month_data);
        }

        fetchData();
    }, []);

    const dimensions = {
        width: 800,
        height: 600,
        margin: { top: 30, right: 30, bottom: 30, left: 60 }
    };

    const september = { name: "September", color: "#999999", items: prevMonthData };
    const october = { name: "October", color: "#000000", items: currMonthData };


    return (
        <div>
            <MultilineChart
                data={[september, october]}
                dimensions={dimensions}
            />
        </div>
    );
}

export default DailyVolume;
