/** MultilineChart.js */
import React from "react";
import * as d3 from "d3";

const MultilineChart = ({ data, dimensions }) => {
    const svgRef = React.useRef(null);
    const { width, height, margin } = dimensions;
    const svgWidth = width + margin.left + margin.right;
    const svgHeight = height + margin.top + margin.bottom;

    React.useEffect(() => {
        let curr_max_day = 0;
        let curr_max_amount = 0;
        let yScaleToUse = [];
        let xScaleToUse = [];

        for (let i = 0; i < data.length; i++) {
            if (data[i].items.length > 0) {
                const last_day = data[i].items[data[i].items.length - 1];
                if (last_day.date > curr_max_day) {
                    xScaleToUse = data[i].items;
                    curr_max_day = last_day.date;
                }

                if (last_day.total_amount > curr_max_amount) {
                    yScaleToUse = data[i].items;
                    curr_max_amount = last_day.total_amount;
                }
            }
        }
        const xScale = d3.scaleLinear()
            .domain(d3.extent(xScaleToUse, (d) => d.date))
            .range([0, width]);


        const yScale = d3.scaleLinear()
            .domain([
                0,
                d3.max(yScaleToUse, (d) => d.total_amount) + 10000
            ])
            .range([height, 0]);

        // Create root container where we will append all other chart elements
        const svgEl = d3.select(svgRef.current);
        svgEl.selectAll("*").remove(); // Clear svg content before adding new elements 
        const svg = svgEl
            .append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);
        // Add X grid lines with labels
        const xAxis = d3.axisBottom(xScale)
            .ticks(10)
            .tickSize(-height + margin.bottom);
        const xAxisGroup = svg.append("g")
            .attr("transform", `translate(0, ${height})`)
            .call(xAxis);
        xAxisGroup.select(".domain").remove();
        xAxisGroup.selectAll("line").attr("stroke", "rgba(0, 0, 0, 0.2)");
        xAxisGroup.selectAll("text")
            .attr("opacity", 0.5)
            .attr("color", "black")
            .attr("font-size", "0.75rem");
        // Add Y grid lines with labels
        const yAxis = d3.axisLeft(yScale)
            .ticks(10)
            .tickSize(-width)
            .tickFormat((val) => `$${val}`);
        const yAxisGroup = svg.append("g").call(yAxis);
        yAxisGroup.select(".domain").remove();
        yAxisGroup.selectAll("line").attr("stroke", "rgba(0, 0, 0, 0.2)");
        yAxisGroup.selectAll("text")
            .attr("opacity", 0.5)
            .attr("color", "black")
            .attr("font-size", "0.75rem");
        // Draw the lines
        const line = d3.line()
            .x((d) => xScale(d.date))
            .y((d) => yScale(d.total_amount));
        svg.selectAll(".line")
            .data(data)
            .enter()
            .append("path")
            .attr("fill", "none")
            .attr("stroke", (d) => d.color)
            .attr("stroke-width", 3)
            .attr("d", (d) => line(d.items));
    }, [data]); // Redraw chart if data changes

    return <svg ref={svgRef} width={svgWidth} height={svgHeight} />;
};

export default MultilineChart;
