import {
    HashRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import FailedPayments from "./FailedPayments";
import UserCustomCategories from "./UserCustomCategories";
import DailyVolume from "./DailyVolume";
import RefundMatching from "./RefundMatching";

const RouterPage = () => {

    return (
        <Router>
            <div>
                <nav>
                    <ul>
                        <li><Link to="/failed-payments">Failed Payments</Link></li>
                        <li>
                            <Link
                                to="/user-card-custom-categories">User Card Custom Categories
                            </Link>
                        </li>
                        <li><Link to="/daily-volume">Daily Volume</Link></li>
                        <li><Link to="/refund-matching">Manual Refunds Review</Link></li>
                    </ul>
                </nav>

                {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
                <Switch>
                    <Route path="/failed-payments">
                        <FailedPayments />
                    </Route>
                    <Route path="/user-card-custom-categories">
                        <UserCustomCategories />
                    </Route>
                    <Route path="/daily-volume">
                        <DailyVolume />
                    </Route>
                    <Route path="/refund-matching" >
                        <RefundMatching />
                    </Route>
                    <Route path="/">
                        <DailyVolume />
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}

export default RouterPage;
