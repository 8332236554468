import React, { useState, useEffect } from 'react';
import "./FailedPayments.css";

const FailedPayments = () => {
    const [failedPayments, setfailedPayments] = useState([]);

    useEffect(() => {

        async function fetchData() {
            const authToken = localStorage.getItem('authToken');
            const result = await fetch(
                'https://prod-percents-internal-monorepo.onrender.com/internal/failed-payments',
                {
                    headers: {
                        'x-access-token': `token ${authToken}`
                    }
                }
            );
            let data = await result.json();
            setfailedPayments(data);
        }

        fetchData();
    }, []);


    const listItems = failedPayments.map((failedPayment: any) => {
        const created = new Date(failedPayment.created);
        const stripe_auth_uri = 'https://dashboard.stripe.com/issuing/authorizations/' + failedPayment.external_authorization_id;
        return <tr key={failedPayment.external_authorization_id}>
            <td>{created.toDateString()}</td>
            <td>
                <a href={stripe_auth_uri} target="_blank" rel="noreferrer">{failedPayment.external_authorization_id}</a>
            </td>
            <td>{failedPayment.merchant_description}</td>
            <td>${failedPayment.amount / 100.0}</td>
            {/* <td>{failedPayment.}</td> */}
        </tr>
    });
    return <div>
        <h1>Failed Payments</h1>
        <table id='failedPayments'>
            <tbody>
                <tr>
                    <th>Time</th>
                    <th>Stripe Authorization ID</th>
                    <th>Merchant Description</th>
                    <th>Amount</th>
                </tr>
                {listItems}
            </tbody>
        </table >

    </div>;
}

export default FailedPayments;
