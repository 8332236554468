import React, { useState, useEffect } from 'react';
import Select from "react-dropdown-select";
import "./FailedPayments.css";

const setUserCustomCategory = async (card_id, value) => {
    const authToken = localStorage.getItem('authToken');
    const set_user_category_url = 'https://prod-percents-internal-monorepo.onrender.com/internal/set-user-custom-category';
    await fetch(set_user_category_url, {
        method: "PUT",
        body: JSON.stringify({
            cardId: card_id,
            categoryId: value.id,
            newCategory: value.value,
            earningRate: value.earning_rate
        }),
        headers: {
            'x-access-token': `token ${authToken}`,
            "Content-Type": "application/json"
        }
    });
};

const UserCustomCategories = () => {
    const [userCards, setUserCards] = useState([]);

    useEffect(() => {

        async function fetchData() {
            const authToken = localStorage.getItem('authToken');
            const result = await fetch(
                'https://prod-percents-internal-monorepo.onrender.com/internal/user-custom-categories',
                {
                    headers: {
                        'x-access-token': `token ${authToken}`
                    }
                }
            );
            let data = await result.json();
            setUserCards(data);
        }

        fetchData();
    }, []);



    const listItems = userCards.map((userCard) => {
        let current_user_selection = userCard.user_current_custom_categories;

        const custom_category_cols = userCard.global_card_custom_categories.map(custom_category => {
            // custom_category: {"earnings_rate": "3x", "categories": ["a", "b", "c"]}

            let options = [];
            custom_category.categories.forEach(category => {
                options.push({
                    value: category,
                    earning_rate: custom_category.earning_rate,
                    id: custom_category.id,
                    label: (custom_category.earning_rate + ' ' + category)
                })
            });

            // values = currently user selected category, what the dropdown initializes with 
            let values = [];

            for (let i = 0; i < current_user_selection.length; i++) {
                const curr_user_selection = current_user_selection[i];
                if (curr_user_selection.id === custom_category.id) {
                    values.push({
                        value: curr_user_selection.category,
                        id: curr_user_selection.id,
                        earning_rate: curr_user_selection.earning_rate,
                        label: (curr_user_selection.earning_rate + ' ' + curr_user_selection.category)
                    });
                    break;
                }
            }
            // idea to take care of US Bank situation where there's multiple categories of same earning rate: iterate over current selection, find corresponding earnings rate, then pop from current selection
            if (values.length === 1) delete current_user_selection[values[0].label];

            return <td>
                <Select values={values} options={options} onChange={values => {
                    setUserCustomCategory(userCard.user_card_id, values[0]);
                }
                } />
            </td>;
        });

        return <tr key={userCard.user_card_id}>
            <td>{userCard.user_name}</td>
            <td>{userCard.user_id}</td>
            <td>{userCard.last4}</td>
            <td>
                {userCard.global_card}
            </td>
            {custom_category_cols}
        </tr>
    });
    return <div>
        <h1>User Card Custom Categories</h1>
        <table id='userCardsCustomCategories'>
            <tbody>
                <tr>
                    <th>User</th>
                    <th>User Id</th>
                    <th>Last 4</th>
                    <th>Global Card</th>
                    <th>User Custom Category 1</th>
                    <th>User Custom Category 2</th>
                    <th>User Custom Category 3</th>
                </tr>
                {listItems}
            </tbody>
        </table >
    </div>;
}

export default UserCustomCategories;
