import React, { useState, useEffect } from 'react';
import { Json } from 'sequelize/types/utils';
import "./RefundMatching.css";

let dev = false;
const RefundMatching = () => {
    const [unmatchedRefunds, setunmatchedRefunds] = useState([]);

    

    async function fetchData() {
        const authToken = localStorage.getItem('authToken');
        const urlstr = dev ? 'dev-percents-internal.onrender.com' : 'prod-percents-internal-monorepo.onrender.com';
        const result = await fetch(
            `https://${urlstr}/internal/refunds/get-all-manual-refunds`,
            {
                headers: {
                    'x-access-token': `token ${authToken}`
                }
            }
        );
        let data = await result.json();
        setunmatchedRefunds(data);
    }

    useEffect(() => {
        
        fetchData();
    }, []);

    async function postIssueRefund(refundUserTxnId:string,matchedUserTxnId:string|null){
        const authToken = localStorage.getItem('authToken');
        const urlstr = dev ? 'dev-percents-internal.onrender.com' : 'prod-percents-internal-monorepo.onrender.com';
        const response = await fetch(
            `https://${urlstr}/internal/refunds/issue-refund`,
            {
            method: "POST",
            body: JSON.stringify({
                refundUserTxnId,
                matchedUserTxnId,
            }),
            headers: {
                'x-access-token': `token ${authToken}`,
                "Content-Type": "application/json"
            }
            },
        );
        
        if(response.status === 200){
            return {
                success: true,
                body:null,
            };
        }
        else{
            return {
                success: false,
                body: await response.json(),
            }
        }
    }

    function executeRefund(radioName : string,refundId:string) {
        return async () => {
            let selected = null;
            
            const radios = document.getElementsByName( radioName );
            for(const radio of radios){
                const aRad:any = radio;
                if(aRad.checked && !selected){
                    selected = aRad.value;
                }
                else if(aRad.checked && selected){
                    window.alert("More than one radio selected somehow, should not be possible");
                    return;
                }
            }

            if(!selected){
                window.alert("No refund has been selected");
                return;
            }

            let confirmResult = window.confirm("Please confirm refund");
            if(confirmResult){
                const result = await postIssueRefund(refundId,selected);
                if(result.success){
                    window.alert("Succesfully processed refund!");
                }
                else{
                    window.alert("Error processing refund: " + result.body?.err_msg);
                }
            }
            else{
                console.log("refund canceled");
            }
        }
    }

    function switchToDev(){
        dev = !dev;
        console.log("Switching to " + dev);
        fetchData();
    }

    function executeRefundWithoutLink(refundId:string) {
        return async () => {
            let confirmResult = window.confirm("Please confirm this refund has no link");
            if(confirmResult){
                console.log(refundId);
                const result = await postIssueRefund(refundId,null);
                if(result.success){
                    window.alert("Succesfully processed refund!");
                }
                else{
                    window.alert("Error processing refund: " + result.body?.err_msg);
                }
            }
            else{
                console.log("refund canceled");
            }
        }
    }

    function rerunRefundMatching(refundUserTxnId:string){
        return async () => {
            console.log("Running");
            const authToken = localStorage.getItem('authToken');
            const urlstr = dev ? 'dev-percents-internal.onrender.com' : 'prod-percents-internal-monorepo.onrender.com';
            try{
                const response = await fetch(
                    `https://${urlstr}/internal/refunds/rerun-matching`,
                    {
                    method: "POST",
                    body: JSON.stringify({
                        refundUserTxnId,
                    }),
                    headers: {
                        'x-access-token': `token ${authToken}`,
                        "Content-Type": "application/json"
                    }
                    },
                );
                console.log("ran " + JSON.stringify(response));
                if(response.status === 200){
                    console.log("Success");
                    window.alert("Successfully reran refund matching!");
                }
                else{
                    console.log("Failure");
                    window.alert("Failure rerunning refund matching");
                }
            }
            catch(err){
                console.log("Error");
                window.alert("Error rerunning refund matching");
            }
            
        }
    }

    function issueRefund(inputfieldname:string, refundUserTxnId:string){
        return async () => {
            const inputField:any = document.getElementById(inputfieldname);
            console.log(inputField?.value);
            const input = parseInt(inputField?.value);
            if(isNaN(input)){
                window.alert("number is not imputed");
                return;
            }

            let confirmResult = window.confirm("Please confirm refund is for " + input);
            if(confirmResult){
                const result = await postIssueRefund(refundUserTxnId,`${input}`);
                if(result.success){
                    window.alert("Successfully processed refund!");
                }
                else{
                    window.alert("Error processing refund: " + result.body?.err_msg);
                }
            }
            else{
                console.log("refund canceled");
            }
        }
    }

    const listItems = unmatchedRefunds.map((UserTransaction: any,index) => {
        try{
        const refundDate = new Date(UserTransaction.created + "");
        let tableRows = [<tr key={UserTransaction.id} className='refundRow'>
            <td>Incoming Refund: </td>
            <td>{UserTransaction.id}</td>
            <td>{UserTransaction.user_id}</td>
            <td>{refundDate.toUTCString()}</td>
            <td>${UserTransaction.amount/100.0}</td>
            <td>{UserTransaction.merchant_description}</td>
            <td>{UserTransaction.merchant_zip}</td>
            <td>{UserTransaction.mcc}</td>
        </tr>];
        const radioName = 'output_' + index;
        const inputFieldName = 'input_' + index;

        const highConfidenceCandidates = UserTransaction?.refund_matching_results?.high_confidence_results;
        const mediumConfidenceCandidates = UserTransaction?.refund_matching_results?.expanded_results;
        let highConfdienceLst = [];
        if(highConfidenceCandidates){
            let confidence_index = 0;
            for(const candidate of highConfidenceCandidates){
                highConfdienceLst.push(candidate.userTxn.id);

                let amountClass = '';
                let merchantDescriptionClass = '';
                let merchantZipClass = '';
                let mccClass = '';

                if(candidate.matchedItems.includes('amount_equals')){
                    amountClass = 'highConfidenceMatch'
                }

                if(candidate.matchedItems.includes('merchant_description')){
                    merchantDescriptionClass = 'highConfidenceMatch'
                }

                if(candidate.matchedItems.includes('mcc')){
                    mccClass = 'highConfidenceMatch'
                }

                if(candidate.matchedItems.includes('merchant_zip')){
                    merchantZipClass = 'highConfidenceMatch'
                }

                const date = new Date(candidate.userTxn.created + "");

                const radioId = 'radio_'+index+'_'+confidence_index;
                let newRow = <tr key={candidate.userTxn.id}>
                    <td>Candidate: </td>
                    <td>{candidate.userTxn.id}</td>
                    <td>{candidate.userTxn.user_id}</td>
                    <td>{date.toUTCString()}</td>
                    <td className={amountClass}>${candidate.userTxn.amount/100.0}</td>
                    <td className={merchantDescriptionClass}>{candidate.userTxn.merchant_description}</td>
                    <td className={merchantZipClass}>{candidate.userTxn.merchant_zip}</td>
                    <td className={mccClass}>{candidate.userTxn.mcc}</td>
                    <td>{candidate.userTxn.is_business?"true":"false"}</td>
                    <td>{candidate.userTxn.payment_card_user_card}</td>
                    <td className='highConfidence'>{candidate.confidence}</td>
                    <td><input type="radio" id={radioId} name={radioName} value={candidate.userTxn.id}/></td>
                </tr>
                tableRows.push(newRow);
                confidence_index += 1;
            }
        }

        if(mediumConfidenceCandidates){
            let confidence_index = 0;
            for(const candidate of mediumConfidenceCandidates){
                if(!highConfdienceLst.includes(candidate.userTxn.id))
                {
                    let amountClass = '';
                    let merchantDescriptionClass = '';
                    let merchantZipClass = '';
                    let mccClass = '';

                    if(candidate.matchedItems.includes('amount_equals')){
                        amountClass = 'highConfidenceMatch'
                    }

                    if(candidate.matchedItems.includes('merchant_description')){
                        merchantDescriptionClass = 'highConfidenceMatch'
                    }

                    if(candidate.matchedItems.includes('mcc')){
                        mccClass = 'highConfidenceMatch'
                    }

                    if(candidate.matchedItems.includes('merchant_zip')){
                        merchantZipClass = 'highConfidenceMatch'
                    }
                    
                    const date = new Date(candidate.userTxn.created + "");

                    const radioId = 'radio_'+index+'_'+confidence_index;
                    let newRow = <tr key={candidate.userTxn.id}>
                        <td>Candidate: </td>
                        <td>{candidate.userTxn.id}</td>
                        <td>{candidate.userTxn.user_id}</td>
                        <td>{date.toUTCString()}</td>
                        <td className={amountClass}>${candidate.userTxn.amount/100.0}</td>
                        <td className={merchantDescriptionClass}>{candidate.userTxn.merchant_description}</td>
                        <td className={merchantZipClass}>{candidate.userTxn.merchant_zip}</td>
                        <td className={mccClass}>{candidate.userTxn.mcc}</td>
                        <td>{candidate.userTxn.is_business?"true":"false"}</td>
                        <td>{candidate.userTxn.payment_card_user_card}</td>
                        <td className='mediumConfidence'>{candidate.confidence}</td>
                        <td><input type="radio" id={radioId} name={radioName} value={candidate.userTxn.id}/></td>
                    </tr>
                    tableRows.push(newRow);
                }
                confidence_index += 1;
            }
        }

        return <div>
            <button onClick={
                executeRefund(radioName,UserTransaction.id)
            }>Refund</button>
            <button onClick={
                executeRefundWithoutLink(UserTransaction.id)
            }>Standalone Refund</button>
            <input type="text" id={inputFieldName} name={inputFieldName}></input>
            <button onClick={
                issueRefund(inputFieldName,UserTransaction.id)
            } className='issueButton'>Issue refund</button>
            <button onClick={
                rerunRefundMatching(UserTransaction.id)
            } className='rerunButton'>Rerun matching</button>
        <table id='refundsTable'>
            <tbody>
                <tr>
                    <th></th>
                    <th>UserTransactions.id</th>
                    <th>User.id</th>
                    <th>Time Created</th>
                    <th>Amount</th>
                    <th>Merchant Description</th>
                    <th>Merchant Zip</th>
                    <th>MCC</th>
                    <th>is_business</th>
                    <th>Payment card id</th>
                    <th>Confidence</th>
                    <th></th>
                </tr>
                {tableRows}
            </tbody>
        </table >
        </div>;
        }
        catch(err){
            console.log(`Error creating table: ${err}`);
            console.log(UserTransaction);
            return <div>
            <h1>Error grabbing refund</h1>
        </div>;
        }
        
    });

    if(listItems.length === 0){
        return <div>
            <h1>All refunds are matched, no action! <span> 👍 </span></h1>
            <h1>Unmatched refunds {dev?"- dev":""}</h1>
            <input onClick={switchToDev} 
            type="checkbox"/><span>Dev</span>
            {listItems}
        </div>
        ;
    }

    return <div>
        <h1>Unmatched refunds {dev?"- dev":""}</h1>
        <input onClick={switchToDev} 
        type="checkbox"/><span>Dev</span>
        {listItems}
    </div>;
}

export default RefundMatching;
